$(function(){
  if($('.js-mc-height').length){
    $('.js-mc-height').matchHeight();
  }

  $('#nav-btn').on('click',function(){
    $(this).toggleClass('is-open');
    if($(this).hasClass('is-open')) {
      $('#nav-btn-text').text('CLOSE')
    } else {
      $('#nav-btn-text').text('MENU')
    }
    $('#sp-nav').slideToggle();
  });

  $('#sp-nav-parent').on('click',function(){
    $(this).toggleClass('is-open')
    $('#sp-nav-child').slideToggle()
  })

})